<template>
  <b-modal
    id="cms-create-game-cover"
    title="New Game Cover"
    no-close-on-backdrop
    @ok="onOk"
  >
    <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
      <div class="text-right">
        <b-button variant="success" @click="onAddGameCover">+</b-button>
      </div>
      <hr />
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div v-for="(item, index) of createGameCoverForm.games" :key="index">
          <ValidationProvider
            v-slot="{ errors }"
            name="image_src"
            :rules="{
              required: true,
            }"
          >
            <b-form-group label="Image">
              <CmsFileSelect
                :value="item.imageSrc"
                :state="errors[0] ? false : null"
                @update="(value) => (item.imageSrc = value || '')"
              />
            </b-form-group>
          </ValidationProvider>
          <b-form-group label="Provider game">
            <b-form-select v-model="item.gameId">
              <b-form-select-option selected value=""
                >--- Select game ---</b-form-select-option
              >
              <b-form-select-option
                v-for="pg of providerGames"
                :key="pg.id"
                :value="pg.id"
                >{{ pg.name }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <hr />
        </div>
        <small class="text-warning"
          >Select the same game will override previous config.</small
        >
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
  },
  data() {
    return {
      createGameCoverForm: {
        games: [{ ...this.template }],
      },
      template: {
        gameId: '',
        imageSrc: '',
      },
    }
  },
  computed: {
    ...mapState({
      isCreatingSiteGameBanners: (state) => state.cms.isCreatingSiteGameBanners,
    }),
    ...mapGetters(['siteId', 'providerGames']),
  },
  methods: {
    ...mapActions(['createCmsGameCovers']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-game-cover'))
      }
    },
    async onSubmit(cb) {
      await this.createCmsGameCovers({
        siteId: this.siteId,
        games: this.createGameCoverForm.games,
      })

      cb()
    },
    onAddGameCover() {
      this.createGameCoverForm.games.push(this.template)
    },
  },
}
</script>
